import React from "react"
import Container from "react-bootstrap/Container"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/SEO"

export default function Home() {
  return (
    <Layout>
      <Seo title="Home" />
      <Container className="container">
        <StaticImage
          src="../assets/images/hero-img.jpg"
          alt="Accommodation outside view"
          placeholder="blured"
          layout="fullWidth"
        />
      </Container>
      <section className="section-description">
        <Container className="container">
          <Container className="container section-description__title-container">
            <div className="row">
              <h1 className="section-description__title">
                A vacation destination on the beachfront watching over the
                beautiful Aegean sea. Minimal design inspired by Greek island
                architecture in combination with modern industrial touches.
              </h1>
            </div>
          </Container>
        </Container>
      </section>
      <section className="section-amenities">
        <Container className="container section-amenities__images">
          <div class="row g-2">
            <div class="col-12 col-md-4">
              <StaticImage
                src="../assets/images/breakfast-1.jpg"
                alt="Delicious breakfast"
                placeholder="blured"
              />
            </div>
            <div class="col-12 col-md-4">
              <StaticImage
                src="../assets/images/breakfast-2.jpg"
                alt="Gourmet choices of bread for breakfast"
                placeholder="blured"
              />
            </div>
            <div class="col-12 col-md-4">
              <StaticImage
                src="../assets/images/breakfast-3.jpeg"
                alt="Beautiful refreshing cocktails"
                placeholder="blured"
              />
            </div>
            <div className="col-12 section-amenities__text">
              <p>
                In the morning, enjoy your coffee and delicious breakfast, take
                a break from swimming by relaxing on our most comfortable
                sunbeds and don’t forget to complete your day with a refreshing
                cocktail at A.bar.
              </p>
            </div>
          </div>
        </Container>
      </section>
      <div class="container w-100 divider"></div>
    </Layout>
  )
}
